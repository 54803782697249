var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[_vm.$style.shipmentText]},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-bold",class:[_vm.$style.text]},[_vm._v("Вид передачи: ")]),_c('span',[_vm._v(_vm._s(_vm.order.delivery_type))])]),_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-bold",class:[_vm.$style.text]},[_vm._v("Склад: ")]),_c('span',[_vm._v(_vm._s(_vm.order.warehouse_name))])]),_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-bold",class:[_vm.$style.text]},[_vm._v("Адрес доставки: ")]),_c('span',[_vm._v(_vm._s(_vm.order.delivery_address))])]),_c('div',{staticClass:"mb-1 d-flex align-center"},[_c('span',{staticClass:"font-weight-bold",class:[_vm.$style.text]},[_vm._v("Сумма доставки: ")]),_c('span',[_vm._v(_vm._s(_vm._f("currency_rub")(_vm.order.delivery_price)))]),(_vm.order.delivery_diff_price && _vm.$route.name === 'reports/orders')?_c('v-tooltip',{attrs:{"right":"","open-delay":"300","color":"primary darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1"},on),[_vm._v("mdi mdi-information-outline")])]}}],null,false,159326899)},[_c('div',{staticStyle:{"width":"320px"}},[_c('span',[_vm._v(" Для бесплатной доставки требуется выбрать товары на сумму "+_vm._s(_vm._f("currency_rub")(_vm.order.delivery_diff_price))+", которые подходят под условия доставки уже выбранных товаров. ")])])]):_vm._e()],1),_c('div',{staticClass:"mb-4"},[_c('span',{staticClass:"font-weight-bold",class:[_vm.$style.text]},[_vm._v("Сумма к отгрузке: ")]),_c('span',[_vm._v(_vm._s(_vm._f("currency_rub")(_vm.order.shipment_price)))])])]),_c('Table',_vm._g(_vm._b({attrs:{"items":_vm.order.products,"headers":_vm.dialogTableCols,"no-data-text":"Заказы, удовлетворяющие выбранным параметрам, отсутствуют","not_resizeble":true},scopedSlots:_vm._u([{key:"item.product_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_code)+" ")]}},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomenclature)+" ")]}},{key:"item.reserve_quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reserve_quantity)+" ")]}}],null,true)},'Table',_vm.$attrs,false),_vm.$listeners)),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"6"}},[_c('Datepicker',{attrs:{"date_clear":_vm.is_date_clear,"min":_vm.min_date,"allowed-dates":_vm.allowed_dates,"date_prop":_vm.shipment_date,"rules":[
          function (v) { return !!v || 'Поле обязательно для заполнения'; } ],"label":"Желаемая дата отгрузки","dense":"","clearable":""},on:{"date_changed":_vm.change_date}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"6"}},[_c('TextField',{class:_vm.$style.email,attrs:{"value":_vm.delivery_date,"dense":"","label":"Дата доставки","disabled":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }